.select-wallet-modal {
  color: #FFF;
  width: 570px !important;
}

.select-wallet-modal .ant-modal-content {
  background-color: #020412;
  border-radius: 8px;
}

.select-wallet-modal .ant-modal-header {
  background-color: #020412;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid #151A30;
  padding: 20px;
}

.select-wallet-modal .ant-modal-close {
  color: #FFF;
  top: 20px;
  right: 10px;
}

.select-wallet-modal .ant-modal-close-x {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.select-wallet-modal .ant-modal-body {
  font-size: 15px;
  line-height: 26px;
  padding: 30px 20px;
}

.select-wallet-modal .ant-modal-title {
  color: #FFF;
  font-size: 20px;
  line-height: 32px;
}

.select-wallet-wrapper {
}

.select-wallet-content {
  overflow: auto;
}

.wallet-item {
  position: relative;
  padding: 8px 12px;
  overflow: hidden;
  border: 2px solid #151A30;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 15px;
  transition-duration: 0.3s;

  .wallet-logo {
    width: 32px;
    height: 32px;
    margin: 6px 2px 6px 8px;
  }

  .wallet-title {
    font-weight: 600;
    flex: 1 1 300px;
    padding: 8px;

  }

  .wallet-install {
    padding: 8px;
  }

  .wallet-install > a {
    color: #42C59A;
    font-weight: 500;
  }


  //&:first-child {
  //  border-top-left-radius: 6px;
  //  border-top-right-radius: 6px;
  //}
  //
  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: #151A30;
  }
}

 .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.75) !important;
  }
