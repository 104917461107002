.footer {
    background: linear-gradient(to top, rgba(0 0 0 / 84%) 0.9, rgba(17 33 29));
    background-size: cover; /* Cover the entire element with the background */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the background image */
    width: 100%;
}

.footer-wrapper {
    font-family: "Space Grotesk", sans-serif;
    .footer {
        background: linear-gradient(to top, rgb(17 33 29 / 80%), black),
            url("../img/herobg.png");
        background-size: cover; /* Cover the entire element with the background */
        background-position: center; /* Center the background image */
        background-repeat: no-repeat; /* Do not repeat the background image */
        width: 100%;
    }
}
.footer-wrapper {
    margin-left: 5px;
    margin-right: 5px;
    ul {
        display: flex;
        justify-content: flex-end;
        flex-wrap: nowrap;

        .nav-item {
            a {
                display: flex;
                flex-direction: column;
                align-items: center;

                .social-link-icon {
                    font-size: 4rem;
                }
                span {
                    white-space: nowrap;
                }
            }
        }
        @media only screen and (max-width: 560px) {
            width: 100%;
            justify-content: space-around;
            .nav-item {
                display: flex;
                justify-content: space-around;
                a {
                    flex-direction: column-reverse;
                    .social-link-icon {
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}
