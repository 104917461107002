$border-line-color: rgb(77, 77, 77);
$border-line: 5px $border-line-color solid;
$border-line-radius: 3px;

.episode {
    *,
    *::before,
    *::after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }
    // .episode-wrapper {
    // .video-wrapper {
    //     width: 100%;
    //     position: relative;
    //     .video-controls-container {
    //         position: absolute;
    //         z-index: 10;
    //         opacity: 0;

    //         transition: opacity 150ms ease-in-out;
    //         color: white;
    //         top: 0;
    //         bottom: 5px;
    //         left: 1px;
    //         right: 0;

    //         display: flex;
    //         flex-direction: column;
    //         justify-content: space-between;

    //         .controls {
    //             display: flex;
    //             gap: 0.5rem;
    //             padding: 0.25rem;
    //             align-items: center;
    //             justify-content: space-around;

    //             button {
    //                 background: none;
    //                 border: none;
    //                 color: inherit;
    //                 padding: 0;
    //                 height: 50px;
    //                 width: 50px;
    //                 font-size: 3rem;
    //                 opacity: 0.85;
    //                 transition: opacity 150ms ease-in-out;
    //             }

    //             button:hover {
    //                 opacity: 1;
    //             }
    //         }

    //         .timelaine-container {
    //             width: 100%;
    //             border: 1px solid red;
    //             margin-bottom: 2px;
    //             height: 20px;
    //         }
    //     }
    //     .video {
    //         border: 2px solid blue;
    //     }
    // }

    // .paused {
    //     .pause-btn {
    //         display: none;
    //     }
    // }

    // .video-wrapper:not(.paused) .play-btn {
    //     display: none;
    // }

    // .video-wrapper:hover .video-controls-container,
    // .paused .video-controls-container,
    // .video-wrapper:focus-within .video-controls-container {
    //     opacity: 1;
    // }
    // }
}

.episode-container {
    background-color: black;
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .episode-container-wrapper {
        background-color: black;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .episode-header {
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            background-color: black;

            .episode-header-wrapper {
                height: 4rem;
                border-bottom: $border-line;
                border-radius: $border-line-radius;
                display: flex;
                justify-content: space-between;
                margin-bottom: 1rem;

                .header-left {
                    height: 100%;
                    .header-logo-wrapper a {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        img {
                            box-sizing: border-box;
                            height: 3rem;
                        }
                    }
                }
            }
        }

        .episode-content {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 4rem; /* Space for the header */
            box-sizing: border-box;
            width: 100%;
            overflow: hidden;

            video {
                width: 90vw; /* 90% of the viewport width */
                max-width: 1000px; /* Ensure it doesn't exceed 1000px */
                height: auto; /* Maintain aspect ratio */
            }
        }
    }
}

.episode-footer {
    display: none;
    position: absolute;
    bottom: 0;
    width: 90vw;
    height: 4rem;
    .episode-footer-wrapper {
        border-top: $border-line;
        border-radius: $border-line-radius;
        display: flex;
        justify-content: space-between;
    }
}

.episode {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    max-width: 90vw;
    min-width: 150px;

    .episode-wrapper {
        width: 100%;
        max-width: 800px; /* Adjust as needed */
        display: flex;
        flex-direction: column;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
    }
}

// @import "./VideoPlayer.scss";
