.account-list {
    position: relative;
    max-width: 650px;
    margin: 0 auto;
    .center-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
        padding-bottom: 50px;
    }
}

.account-item {
    padding: 20px 10px;
    @media (min-width: 768px) {
        padding: 35px 45px;
    }

    border: 1px solid #01b69b;
    border-radius: 8px;
    text-align: center;
    background: rgba(-62, 34, 40, 0.85);

    .info {
        margin-bottom: 16px;
    }
}

.account-item-info {
    display: flex;
    flex-wrap: wrap;
    color: white;
}

.account-item__title {
    font-size: 22px;
    // line-height: 26px;
    font-weight: 500;
    padding-right: 5px;
    color: white;
    margin-bottom: 0;

    &.price {
        font-size: 30px;
        font-weight: normal;
        padding-top: 0px;
        letter-spacing: 3px;
    }

    &.max-limit {
        padding-top: 30px;
    }
}

.account-item__content {
    font-size: 22px;
    line-height: 26px;
    color: #7b8098;
    text-overflow: ellipsis;
    overflow: hidden;
    color: white;
    margin-bottom: 0;
}

/*custom*/
.available-count {
    font-size: 5rem;
    font-weight: 800;
    text-align: center;
    color: white;
    margin-bottom: 0;

    span {
        color: #01b69b;
    }
}

.comeback-msg {
    border: none;
    font-size: 30px;
}

.form-select-wrapper {
    width: 100%;
    color: white;
    padding: 10px 30px 15px 30px;
    font-size: 22px;
    .form-select {
        width: auto;
        color: black;
        text-align: center;
        font-size: 30px;
        outline: none;
    }
}

.buy-btn {
    font-size: 30px;
}

.ant-btn {
    height: auto;
    background: linear-gradient(
        0deg,
        rgba(57, 115, 120, 1) 0%,
        rgba(4, 181, 155, 1) 100%
    );
    transition: background 0.4s;
    span {
        color: white;
        font-size: 35px;
    }
    &:hover {
        background: linear-gradient(
            0deg,
            rgba(57, 115, 120, 1) 0%,
            rgba(0, 202, 202, 1) 100%
        );
    }
}

.ant-btn-primary {
    &.buy-btn {
        background: linear-gradient(
            0deg,
            rgba(57, 115, 120, 1) 0%,
            rgba(4, 181, 155, 1) 100%
        );
        color: black;
        border: none;
        border-radius: 8px;
        width: 310px;
        transition: all 0.4s;
        // media query for tablet and above
        @media (min-width: 768px) {
            &:hover {
                color: black;
                width: 350px;
                background: #00caca;
            }
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.3;
            width: 310px;
        }
    }

    &.view-punks-text {
        background: none !important;
        margin: 30px auto 10px;
        outline: 0;
        span {
            color: white !important;
            font-size: 30px !important;
            text-decoration: underline;
            outline: 0;
            letter-spacing: 2px;
        }
    }

    &.sub-wallet-btn {
        background: linear-gradient(
            0deg,
            rgba(57, 115, 120, 1) 0%,
            rgba(4, 181, 155, 1) 100%
        );
        transition: all 0.4s;
        span {
            color: black;
            font-size: 30px;
            padding: 5px;
        }
        &:hover {
            background: #00caca;
        }
    }
}

.allow-mint {
    font-size: 35px;
    line-height: 35px;
}

.ant-input-number {
    border: none;
    color: white;
    border: none;
    border-bottom: 6px solid #01b69b;
    background-color: transparent;
    border-radius: 0;
    font-size: 55px;
    &-focused {
        border-color: #01b69b !important;
        box-shadow: none;
    }
    &-handler-wrap {
        background: linear-gradient(
            0deg,
            rgba(57, 115, 120, 1) 0%,
            rgba(4, 181, 155, 1) 100%
        );
    }
    &-handler-up-inner {
        font-size: 16px;
    }
    &:hover,
    &:focus {
        border-color: #01b69b !important;
        border-right: none;
    }
}

.value-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .select-amount {
        font-size: 5rem;
        margin: 0;
    }
}

//NFT Viewer specific
.nft-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: 0 auto;
    .nft-image {
        width: 50%;
        padding: 10px;
        // media query for tablet and above
        @media (min-width: 768px) {
            width: 25%;
        }
        img {
            width: 100%;
            border-radius: 8px;
        }
    }

    .nft-name {
        font-size: 30px;
    }
}

.NFTViewerModal {
    .ant-card {
        width: 50%;
        @media (min-width: 768px) {
            width: 33.33%;
        }
        border-radius: 8px;
        background: rgba(0, 34, 40, 0.85);
    }
    .ant-statistic-content-value,
    .attrDesc {
        font-size: 21px;
        margin: 0;
        color: white;
        text-align: center;
    }

    .ant-statistic-title {
        font-size: 18px;
        margin-bottom: 0;
        line-height: 10px;
        color: #06b69b;
    }

    .ant-statistic {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .ant-modal-footer {
        display: none;
    }

    .ant-modal-close-x {
        font-size: 25px;
        color: #06b69b;
        &:hover {
            color: #06b69b;
        }
    }

    .ant-modal-content {
        background: rgba(0, 34, 40, 0.85);
        border-radius: 8px;
        box-shadow: 0 20px 20px black;
    }

    .ant-modal-mask {
        background-color: rgba(0, 0, 0, 0.75) !important;
    }

    .ant-modal-title {
        font-size: 35px;
        color: white;
        text-align: center;
    }

    .ant-modal-header {
        border: none;
        background: rgba(0, 34, 40, 0.85);
        padding: 24px 24px 16px;
        border-radius: 8px 8px 0 0;
    }

    .modal-preview-container {
        width: 100%;
        @media (min-width: 768px) {
            width: 30%;
        }

        img {
            width: 100%;
            border-radius: 8px;
        }
    }

    .ant-modal-body {
        display: flex;
        align-items: center;
        padding: 25px 35px;
        flex-direction: column;
        @media (min-width: 768px) {
            flex-direction: row;
        }
    }

    .attr-container {
        width: 100%;
        padding: 0;
        @media (min-width: 768px) {
            width: 70%;
            padding: 0 25px;
        }
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        padding-top: 15px;
        justify-content: center;
        .ant-card-body {
            padding: 25px;
            border: 1px solid #00b69b;
            border-radius: 8px;
            margin: 5px;
        }
    }
}

//Ant Image component overrides
.ant-image-preview-img {
    max-width: 50%;
}

.ant-image-preview-operations-operation {
    display: none;
}

.ant-image-preview-operations-operation:first-of-type {
    display: block;
}

.ant-image-preview-img-wrapper {
    background: rgba(0, 34, 40, 0.85);
}

.ant-image-preview-operations-icon {
    font-size: 40px;
}

.ant-image-mask-info {
    font-size: 30px;
}

.ant-image-mask {
    background: rgba(0, 34, 40, 0.85);
    border: 1px solid #01b69b;
    border-radius: 8px;
}

//overall overrides
.sub-wallet-btn-normal-size > span:last-child {
    padding: 15px;
}

.wallet-item {
    border: 2px solid #01b69b;
    .wallet-title {
        font-size: 25px;
    }
    .wallet-install > a {
        font-size: 22px;
    }
}

.select-wallet-modal .ant-modal-title {
    font-size: 30px;
    text-align: center;
    padding: 5px 10px;
}

.nft-image img {
    transition: 0.2s;
    cursor: pointer;
    // media query for tablet and above
    @media (min-width: 768px) {
        &:hover {
            transform: scale(1.1);
        }
    }
}

//incease & decrease
.increase,
.decrease {
    font-size: 70px;
    background: none;
    border: none;
    cursor: pointer;
    &:hover {
        color: #01b69b;
    }
    &:disabled {
        cursor: not-allowed;
        opacity: 0.3;
        &:hover {
            color: white;
        }
    }
}

.ant-message-custom-content {
    font-size: 30px;
}

.wallet-header-content {
    padding: 20px 0 0;

    .wallet-logo {
        height: 70px;
    }
}

.wallet-info-page {
    padding: 5px 8px 50px !important;
}

.total-amount-seperator {
    font-weight: normal;
    color: white !important;
}

.account-select-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20px;
    #wallet-select {
        background: transparent;
        font-size: 22px;
        text-align: center;
        border: 2px solid #01b69b;
        padding: 2px 10px;
        border-radius: 8px;
        option {
            background: #041e23;
            color: white;
        }
        &:focus,
        &:hover {
            outline: none;
        }
    }
}

.modal-preview-container {
    #punk-collection-dropdown {
        background: transparent;
        font-size: 22px;
        text-align: center;
        border: 2px solid #01b69b;
        padding: 2px 10px;
        width: 100%;
        color: white;
        border-radius: 8px;
        option {
            background: #041e23;
            color: white;
        }
        &:focus,
        &:hover {
            outline: none;
        }
    }
}

.nft-viewer-title {
    font-size: 30px;
    color: white;
    margin: 0 auto;
    text-align: center;
}

.white-text {
    color: white;
}

.mb-0 {
    margin-bottom: 0;
}

//NFT Viewer specific
.select-nft-text {
    font-size: 27px;
    color: white;
    text-align: center;
    padding: 15px 0;
}

.punks-count {
    font-size: 30px;
    line-height: 40px;
    padding: 0 15px;
    @media (min-width: 768px) {
        font-size: 45px;
    }
}

.text-center {
    text-align: center;
}

.mx-auto {
    margin: 0 auto;
}

//progress bar overrides
.ant-progress-success-bg,
.ant-progress-bg {
    border-radius: 0;
}

.ant-progress-inner {
    border: 2px solid #04b59b;
    border-radius: 8px;
    width: 75%;
}

.ant-progress {
    margin: 30px 0;
}

.ant-progress-text {
    display: inline-block;
    width: 6rem;
    margin-left: 8px;
    color: white;
    font-size: 2em;
    line-height: 1.2;
    white-space: nowrap;
    text-align: left;
    vertical-align: middle;
    word-break: normal;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.ant-progress-show-info .ant-progress-outer {
    margin-right: 0;
    padding-right: 0;
}

.coming-soon-title {
    font-size: 4rem;
    line-height: 4rem;
}

.coming-soon-subtitle {
    font-size: 2rem;
    line-height: 2rem;
}

.nftViewerContainer {
    background: rgba(0, 34, 40, 0.85);
    border: 1px solid #01b69b;
    border-radius: 8px;
    padding: 40px 0;
    max-width: 1200px;
    margin: 0 auto;
}

.success {
    background: #28a745 !important;
    color: #fff;
}

.ant-tabs-tab {
    font-size: 30px;
    color: white;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #01b69b;
    text-shadow: 0 0 0.25px #01b69b;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 4px;
}

.ant-tabs-ink-bar {
    background: #01b69b;
}

.ant-tabs-tab:hover {
    color: #01b69b;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
    font-size: 30px;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
    font-size: 25px;
}

.ant-modal-content {
    background-color: rgba(0, 0, 0, 0.5);
    .ant-modal-confirm-body .ant-modal-confirm-title,
    .ant-modal-confirm-content {
        color: white;
    }
    .ant-modal-confirm-btns {
        width: 100%;
        .ant-btn-primary {
            width: 100%;
            border: none;
        }
    }
}

table {
    display: grid;
    grid-template-rows: auto 1fr 1fr;

    thead,
    tbody {
        tr {
            display: grid;
            grid-template-columns: 1fr 10rem 1fr 1fr 1fr;
        }
    }
}

@media (max-width: 465px) {
    table {
        thead,
        tbody {
            tr {
                grid-template-columns: 4rem 5rem 1fr 1fr;
                th:nth-child(2),
                td:nth-child(2) {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                th:nth-child(5),
                td:nth-child(5) {
                    display: none;
                }
            }
        }
    }
}

// <table style="table-layout: auto;">
// <colgroup></colgroup>
// <thead class="ant-table-thead">
// 		<tr>
// 			<th class="ant-table-cell">Silver Earned</th>
// 			<th class="ant-table-cell">Address</th>
// 			<th class="ant-table-cell">Created Date</th>
// 			<th class="ant-table-cell">Last Stake Date</th>
// 			<th class="ant-table-cell">Last Auto Stake Date</th>
// 		</tr>
// </thead>
// <tbody class="ant-table-tbody">
// 		<tr data-row-key="keyString" class="ant-table-row ant-table-row-level-0">
// 			<td class="ant-table-cell">4 silver</td>
// 			<td class="ant-table-cell">lddkfjsdklfjllddkfjsdklfjllddkfjsdklfjl</td>
// 			<td class="ant-table-cell">today, tomorrow ?</td>
// 			<td class="ant-table-cell">6 days ago</td>
// 			<td class="ant-table-cell">tomorrow</td>
// 		</tr>
// </tbody>

// </table>
