@import "./colors.scss";

h1 {
    @media (max-width: 470px) {
        font-size: 2rem;
    }
}

.coming-soon {
    color: $primary;
}

.join-punks-title {
    // padding-top: 225px;
    // padding-top: 15rem;
}

.homepage {
    .homepage-header {
        .header-logo {
            flex-shrink: 1;
        }
    }
}

@media only screen and (min-width: 1000px) {
    .homepage {
        .homepage-header {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;

            .header-logo {
                text-align: center;
            }
        }
    }
}

@media only screen and (min-width: 1150px) {
    .homepage {
        .homepage-header {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .homepage {
        .homepage-header {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            .header-logo {
                text-align: center;
            }
        }
    }
}
