.App {
    height: 100vh;
}

body {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.44), rgb(17, 33, 29)),
        url("~/public/bg.png");
    // url("../public/bg.png");
    background-position: center;
    background-size: cover !important;
}

.main-layout {
    height: 100%;
    // height: 85vh;
    background: rgba(0, 0, 0, 0.85);
}

.main-content {
    height: 100%;
    height: 100vh;
    position: relative;
    overflow: auto;
}

// .main-content.-dark {
//   background-color: #020412;
// }

.main-content.-light {
    // background-color: #FFF;

    .welcome-content__text,
    .wallet-title,
    .wallet-info-page__text,
    .account-item__title,
    .metadata-item__title {
        color: #020412;
    }

    .account-item__content,
    .metadata-item__content {
        color: #888;
    }

    .select-wallet-modal .ant-modal-content {
        background-color: #fff;
    }

    // .account-item, .metadata-item, .wallet-header-wrapper {
    //   border-color: #EEE;
    // }

    .sub-wallet-sign-btn {
        background-color: #f0f4ff;
    }

    .sub-wallet-sign-btn > span {
        color: #01b69b;
    }
}

.boxed-container {
    padding: 50px 16px 10px;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
    // max-width: 800px;
}

.select-wallet-modal.-light {
    .ant-modal-content,
    .ant-modal-header {
        background-color: #fff;
    }

    .ant-modal-header {
        border-bottom-color: #eee;
    }

    .ant-modal-title,
    .ant-modal-close,
    .wallet-title {
        color: #020412;
    }

    .wallet-item {
        border-color: #eee;
    }

    .wallet-item:hover {
        background-color: #eee;
    }
}

.sub-wallet-switch-theme {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 1000;
    background-color: #01b69b;
}

@media (max-width: 840px) {
    .sub-wallet-switch-theme {
        right: 300px;
    }
}

@media (max-width: 768px) {
    .sub-wallet-switch-theme {
        right: 250px;
    }
}

@media (max-width: 600px) {
    .sub-wallet-switch-theme {
        right: 200px;
    }
}

.ant-pagination-item {
    display: none;
}

.ant-table-thead > tr > th,
.ant-table {
    color: white;
    background: transparent;
}

.ant-table-cell {
    font-size: 18px;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: transparent;
}

.ant-empty {
    color: white;
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: transparent;
}
