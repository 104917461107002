.store-header {
    position: relative;
    min-height: 50px;
    background-color: rgba(0, 0, 0, 0.75);

    font-family: "Space Grotesk", sans-serif;
    font-size: 1.5rem;
    .store-header-wrapper {
        padding: 10px 25px;
        display: grid;
        grid-template-columns: 1fr 3fr;
        align-items: center;
        .store-nav-menu-wrapper {
            .store-nav-menu {
                display: flex;
                justify-content: space-around;
                gap: 5px;
                flex-wrap: wrap;
                .store-nav-menu-item {
                    white-space: nowrap;
                }
            }
        }
    }
}
@media only screen and (max-width: 930px) {
    .store-header {
        .store-header-wrapper {
            display: flex;
            align-items: center;
            .store-header-right {
                width: 80vw;
                .store-nav-menu {
                    justify-content: space-around;
                }
            }
        }
    }
}

@media only screen and (max-width: 850px) {
    .store-header {
        .store-header-wrapper {
            .store-nav-menu {
                flex-wrap: wrap;
                font-size: 1.2rem;
                gap: 10px;
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    .store-header {
        .store-header-wrapper {
            flex-direction: column;
        }
    }
}

// @media only screen and (min-width: 800px) {
//     .store-header {
//         .store-header-wrapper {
//             .store-nav-menu {
//                 &:not(last-child) {
//                     &:after {
//                         content: " |";
//                     }
//                 }
//                 // &:nth-child(-n + 1) {
//                 //     &:before {
//                 //         content: "|";
//                 //     }
//                 // }
//             }
//         }
//     }
// }
