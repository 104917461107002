// common
.hidden {
    display: none;
}

ul {
    list-style-type: none;
    padding-left: 0;
}
@import "./App.scss";
@import "./index.scss";
@import "./Header.scss";
@import "./Footer.scss";
@import "./NoMatch.scss";
@import "./Homepage.scss";
@import "./Contents.scss";
@import "./ComingSoon.scss";
@import "./TheLegend.scss";
@import "./Footer.scss";
@import "./TheLegendEpisode.scss";
@import "./Store.scss";
