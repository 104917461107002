// colors

$title-color: #ffd51e;
$content-color: #0cae94;

@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");

.contents-wrapper {
    font-family: "VT323", monospace;

    font-size: 1.5rem;

    height: 100vh;
    background-color: black;

    .contents {
        padding: 10vh 5vw;
        .title-wrapper {
            padding-bottom: 10vh;
            width: 100%;
            .title {
                color: $title-color;
            }
        }
        .content-wrapper {
            .content {
                display: grid;
                gap: 30px;
                grid-template-columns: 8fr 11fr 1fr;

                .section {
                    // testng
                    align-self: center;
                    // end of testing
                    .section-subtitle {
                        color: $title-color;
                    }
                    .section-list {
                        color: $content-color;
                    }
                }

                .about-us {
                    grid-column-start: 1;
                    grid-column-end: 2;

                    grid-row-start: 1;
                    grid-row-end: 2;

                    align-self: stretch;
                }

                .the-legend {
                    grid-column-start: 2;
                    grid-column-end: 3;

                    grid-row-start: 1;
                    grid-row-end: 2;
                }

                .collection-breakdown {
                    grid-column-start: 1;
                    grid-column-end: 2;

                    grid-row-start: 2;
                    grid-row-end: 3;
                }

                .misc {
                    grid-column-start: 2;
                    grid-column-end: 3;

                    grid-row-start: 2;
                    grid-row-end: 3;
                }
            }
        }
    }
}
