.store {
    font-family: "Space Grotesk", sans-serif;
    color: white;
    .store-wrapper {
        position: relative;
        // background: linear-gradient(to bottom, rgb(0 0 0 / 64%), rgb(17 33 29)),
        //     url("../img/herobg.png");
        // background-size: cover; /* Cover the entire element with the background */
        // background-position: center; /* Center the background image */
        // background-repeat: no-repeat; /* Do not repeat the background image */
        width: 100%;

        .info {
            padding-top: 0;
            font-size: 0.8rem;
            font-style: italic;
            overflow: hidden;
            max-width: 100%;
            * {
                overflow: hidden;
                max-width: 100%;
            }
            .accountTitle {
                text-align: center;
            }
            .btn {
                margin-left: 8px;
            }
        }

        .store-title {
            display: flex;
            justify-content: center;
            .store-title-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                h2 {
                    margin-bottom: 3px;
                }
                h5,
                .info {
                    padding-top: 0;
                    font-size: 0.9rem;
                    font-style: italic;
                }
            }
        }

        .select-reward {
            display: flex;
            justify-content: center;
            .select-reward-wraper {
                display: flex;
                gap: 5px;
                flex-wrap: wrap;
                justify-content: space-around;
            }
        }

        .selected-reward-wrapper {
            min-height: 40vh;
        }

        .store-content {
            display: flex;
            justify-content: space-around;
            .store-content-wrapper {
                background: linear-gradient(
                    to bottom,
                    rgba(0, 0, 0, 0.02) 1%,
                    rgba(0, 0, 0, 0.05) 2%,
                    rgba(0, 0, 0, 0.1) 3%,
                    rgba(0, 0, 0, 0.2) 4%,
                    rgba(0, 0, 0, 0.3) 5%,
                    rgba(0, 0, 0, 0.4) 30%,
                    rgba(0, 0, 0, 0.4) 45%,
                    rgba(0, 0, 0, 0.4) 70%,
                    rgba(0, 0, 0, 0.2) 90%,
                    rgba(0, 0, 0, 0) 100%
                );
                //  rgba(0, 0, 0, 0.3);
                padding: 4px 4vw;
                border-radius: 5px;

                .balance-wrapper {
                    justify-content: center;
                }

                .details {
                    max-width: 90vw;
                    overflow: hidden;

                    display: flex;

                    *:first-child {
                        &:after {
                            content: "\00a0";
                        }
                    }

                    .discountCode {
                        width: 100%;
                        word-wrap: break-word;
                    }
                }

                .store-item {
                    .store-item-wrapper {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .item-img-wrapper {
                            img {
                                max-width: 600px;
                                width: 80vw;
                                height: 100%;
                                border-radius: 5px;
                            }
                        }

                        .item-name-wrapper {
                            .item-name {
                                text-align: center;
                            }
                        }
                        .buy-code-wrapper {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                        }
                    }
                }

                .store-btn-wrapper {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }

    @media only screen and (max-width: 435px) {
        .large-screen {
            display: none;
        }
    }

    @media only screen and (min-width: 435px) {
        .small-screen {
            display: none;
        }
    }
}
