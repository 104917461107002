@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

body {
  margin: 0;
  font-family: 'VT323', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sub-wallet-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #01b69b;
  border: none;
  border-radius: 8px;
  height: auto;
  outline: 0;
  box-shadow: none;
}

.sub-wallet-btn > span {
  font-size: 15px;
  line-height: 26px;
  font-weight: 500;
  color: #fff;
}

.sub-wallet-btn:hover,.sub-wallet-btn:focus,.sub-wallet-btn:active {
  background-color: #01b69b;
}



.sub-wallet-btn-normal-size {
  padding: 11px 13px;
}

.sub-wallet-btn-normal-size > span:last-child {
  padding: 0 20px;
}

.sub-wallet-btn-small-size {
  padding: 7px 16px;
}

.sub-wallet-btn-small-size > span {
  padding: 0 12px;
}

.sub-wallet-icon-btn {
  padding: 11px 13px;
}

.sub-wallet-sign-btn {
  background-color: #181E42;
  padding: 7px 16px;
}

.sub-wallet-sign-btn > span {
  color: #42C59A;
}

.sub-wallet-sign-btn:hover, .sub-wallet-sign-btn:focus, .sub-wallet-sign-btn:active {
  background-color: #181E42;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}