$SkyCity: "../img/TheLegend/SC-Still.png";
$TheLegendImgs: "../img/TheLegend/";

.the-legend-container {
    // .desktop-only {
    //     display: none;
    // }

    // testing
    color: white;
    // end of testing

    position: relative;
    margin-top: 0;
    background: linear-gradient(rgb(0, 0, 0) 60vw, black);
    // background-color: rgb(17 33 29);
    // background: linear-gradient(to bottom, rgb(0 0 0 / 84%), rgb(17 33 29)),
    //     url("../img/herobg.png");
    background-size: cover; /* Cover the entire element with the background */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the background image */
    min-height: 70vh;
    height: auto;

    .the-legend-container-wrapper {
        background: linear-gradient(
                to bottom,
                rgb(0 0 0) 25%,
                rgb(0 0 0 / 50%),
                rgb(0 0 0)
            ),
            // url("../img/TheLegend/SC-Still.png");
            // url("#{$TheLegendImgs}/SC-Still.png");
            url(#{$SkyCity});
        background-size: cover;
        background-position: center;
        min-height: 50vh;
        height: 70%;

        .the-legend-title-wrapper {
            width: 100%;
            display: flex;
            justify-content: space-around;
            .the-legend-title-img {
                margin-top: -20px;
                min-width: 250px;
                width: 50vw;
            }
        }

        .episodes-contents-navigation {
            background-color: rgba(0, 0, 0, 0.5);
            .episodes-contents-navigation-title {
                text-align: center;
            }
            .episodes-contents-navigation-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                padding: 10px;
                gap: 10px;
                .episodes-contents-item {
                    .episode-title {
                        text-align: center;
                    }
                    .episode-picture {
                        min-height: 150px;
                        min-width: 275px;
                        img {
                            max-width: 100%;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: 825px) {
            .desktop-only {
                display: block;
            }
        }
    }
    @media only screen and (max-width: 580px) {
        .the-legend-container-wrapper {
            .episodes-contents-navigation {
                .episodes-contents-navigation-list {
                    .episodes-contents-item {
                        min-width: 325px;
                        min-height: 175px;
                    }
                }
            }
        }
    }
}
