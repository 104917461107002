.coming-soon-section {
    background-color: black;
    height: 100vh;
    .coming-soon-wrapper {
        .section-subtitle {
            color: gold;
            text-align: center;
        }

        .teaser {
            min-height: 50vh;

            width: 100%;
            background: url("../img/comingSoon/scater-city-soon.png");
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}
